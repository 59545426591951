import React from 'react';
import Navbar from '../components/navBar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AssetView from './assetView'
import Create from './create/createAsset'
import Admin from './admin'
import Logout from './logout'
import ResetPassword from './resetPassword'
import NoPage from './NoPage'
import Header from './header'
import Footer from './footer'
import './App.css';

function App() {
 return (
  <>
  <Header />
  <Router>
    <Navbar />
   <Routes>
     <Route>
       <Route path="/" exact element={<AssetView />} />
       <Route path="createAsset" element={<Create />} />
       <Route path="admin" element={<Admin />} />
       <Route path="logout" element={<Logout />} />
       <Route path="resetPassword" element={<ResetPassword />} />
       <Route path="*" element={<NoPage />} />
     </Route>
   </Routes>
 </Router>
 <Footer />
</>
 )
}

export default App