import React from 'react';
import { baseUrl } from '../shared';
import './App.css';
import Button from '@mui/material/Button';

function Logout() {

    const logOutUser = () => {
        const url = baseUrl + 'user/logout';
    fetch(url, {
      method: 'POST',
      headers: {
          'username': localStorage.getItem('username'),
          'bToken': localStorage.getItem('bToken')
      }})
    .then((response) => response.json())
    .then((data) => {
        console.log('logout',data);
        localStorage.clear();
        window.location.href='/'
    })
    .catch((err) => {
       console.log(err.message);
    });
    };

    return (
    <div className="logout">
      <p>Log out</p>
            <Button
                onClick={logOutUser}
                variant="contained"
                >Log Out
            </Button>

        
            <Button
                variant="contained"
                onClick={(e) => {
                window.location.href='/'
                }}
                >Cancel
            </Button>
    </div>
    )
}
export default Logout;