import React, { useState, useEffect } from "react";
import { Register } from "../components/register"
import { UpdateUser} from "../components/updateUser"
import { ResetUserPassword} from "../components/resetUserPassword"
import Button from '@mui/material/Button';
import './App.css';

function Login() {

  const [loginStatus, setLoginStatus] = useState(false);
  const [createNewUser, setCreateNewUser] = useState(false);
  const [updateUser, setUpdateUser] = useState(false);
  const [resetUserPass, setResettingUserPass] = useState(false);

  const createUser = () => {
    setCreateNewUser(true);
    console.log('create ',createNewUser)
  };  

  const updatingUser = () => {
    setUpdateUser(true);
    console.log('update ',updateUser)
  };  

  const resettingUserPass = () => {
    setResettingUserPass(true);
    console.log('resetUserPass ',resetUserPass)
  };  

  useEffect(()=> {
    if (!localStorage.getItem('token')) {
      setLoginStatus(false);
    } else {
      setLoginStatus(true);
    }
  },[]); 
  
    return (
      <div className="admin">
      { loginStatus && !createNewUser && !updateUser && !resetUserPass && localStorage.getItem('canRegisterUser') === '1' ? (
      
      <Button variant="contained" onClick={createUser}>Create A New User</Button>
      
    ) : null}

  { loginStatus && !createNewUser && !updateUser && !resetUserPass && localStorage.getItem('canUpdateUser') === '1' ? (
      
      <Button variant="contained" onClick={updatingUser}>Update User</Button>
    ) : null
  }

  { loginStatus && !createNewUser && !updateUser && !resetUserPass && localStorage.getItem('canUpdateUser') === '1' ? (
      <Button variant="contained" onClick={resettingUserPass}>Reset User Password</Button>
    ) : null
  }

  { loginStatus && createNewUser && localStorage.getItem('canRegisterUser') === '1' ? (
      < Register />
    ) : null}

  { loginStatus && updateUser && localStorage.getItem('canUpdateUser') === '1' ? (
      <UpdateUser />
    ) : null} 

    { loginStatus && resetUserPass && localStorage.getItem('canUpdateUser') === '1' ? (
        <>
        <ResetUserPassword />
        </>
      ) : null} 
      </div>
    );
  }

export default Login