import * as React from 'react';
import useForm from "../components/processForm";
import './App.css';
import { baseUrl } from '../shared';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';


const FORM_ENDPOINT = baseUrl+'user/passwordreset';

const ResetPassword = () => {
  const additionalData = {
  };

  const logOutUser = () => {
    console.log('logout');
    localStorage.clear();
    window.location.href='/'
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  const [setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(true);
  };

  const { handleSubmit, status, message } = useForm({
    additionalData,
  });

  if (status === "success") {
    return (
      
      <>
      <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open="true"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Success
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
          {message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={logOutUser}>
            OK
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
      </>
    );
  }

  if (status === "error") {
    return (
      <>
      <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open="true"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Error
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
          {message}
          </Typography>
        </DialogContent>
        <DialogActions>
        <Button onClick={logOutUser}>
            OK
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
      </>
    );
  }

  return (
    <div className='resetPassword'>
    <form
      action={FORM_ENDPOINT}
      onSubmit={handleSubmit}
      method="POST"
    >
<Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="oldPassword" 
          name="oldPassword"
          required
          label="Password" 
          variant="outlined" 
          type="password" 
        />
     </Box>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="newPassword" 
          name="newPassword"
          required
          label="New Password" 
          variant="outlined" 
          type="password" 
        />
     </Box>
      {status !== "loading" && (
        <div className="pt-0 mb-3">
          <Button variant="contained"
            type="submit"
          >
            Reset Password
          </Button>
        </div>
      )}

    </form>
    </div>
  )
}
export default ResetPassword;