import React from "react";
import '../pages/App.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

export function Search() {

    return (
<>
<Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
  }}
  autoComplete="off"
>
<TextField 
fullWidth
  margin="dense"
  id="assetInput" 
  label="Search By Asset ID" 
  variant="outlined" 
  type="text" 
  onKeyDown={(e) => (
    e.keyCode === 13 ? window.location.href='/?assetId='+ document.getElementById("assetInput").value : null
    )}
/>
</Box>
  <Button variant="contained" onClick={(e) => { window.location.href='/?assetId='+ document.getElementById("assetInput").value}}> Lookup Asset ID  </Button>
</>
  )}

export default Search