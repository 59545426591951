import React, { useEffect } from "react";
import '../App.css';
import Form from "./createForm";


function Create(createForm) {
 
  useEffect(()=> {
    if (localStorage.getItem('canAddAsset') === '1' ) {
      
    } else {
      window.location.href='/'
    }
  },[]);
  
  return (
    <div className="createAsset">
    <p style={{fontWeight: "bolder", fontSize: "20px", color: "darkorange"}}>New Asset Creation</p>
    <p style={{fontSize: "15px"}}>Enter asset details below in all fields</p>
    <p style={{fontSize: "15px"}}>to create a new Asset in the System.</p>
    <Form />
  <br/>
  <br/>
  </div>
);
}
  export default Create;