import { useState } from "react";
import FirstTab from "./tab-components/FirstTab";

export default function Modal({foundAsset}) {

  const [uploadAsset] = useState(foundAsset);

console.log('upload page: ',uploadAsset)
  return (
    <div className="modalOuter">
      <div className="modalBox">
          <div className="outlet">
            {<FirstTab  foundAsset={uploadAsset}/>}
          </div>
        </div>
      </div>
  );
}
