import React, { useState, useEffect } from "react";
import { baseUrl } from '../../shared.js';
import '../App.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
  
function Inspect({foundAsset}) {
  const [changed, setChanged] = useState(false);
  const [assetEdit] = useState(foundAsset);
  const [modAsset, setModAsset] = useState(foundAsset);


useEffect(()=> {
  console.log(changed);
  console.log('Pass Asset ',assetEdit);
  console.log('Mod Asset ',modAsset);
});


function updateAsset(e) {
  e.preventDefault();
  const url = baseUrl+'id/'+ [assetEdit.id];
  fetch(url, {
      method: 'PATCH',
      headers: {
          'Content-Type': 'application/json',
          'username': localStorage.getItem('username'),
          'bToken': localStorage.getItem('bToken')
      },
      body: JSON.stringify(modAsset),
  })
      .then((response) => {
          if (!response.ok) throw new Error('something went wrong');
          return response.json();
      })
      .then((data) => {
        setModAsset(data);
        window.location.href='/?assetId='+[modAsset.assetId]
      });
}

return (
    <div>
        <div>
        <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 300,
      }}
      autoComplete="off"
    >
        <TextField 
        fullWidth
          margin="dense"
          id="assetId" 
          label="Asset Number" 
          variant="outlined" 
          type="text" 
          InputProps={{
            readOnly: true,
          }}
          value={modAsset.assetId} 
          onChange={(e) => {
            setChanged(true);
            setModAsset({
               ...modAsset,
            assetId: e.target.value,
            });
            }}
        />
        </Box>
    <Box
          margin="dense">
       <FormControl fullWidth>
  <InputLabel>Asset Pass/Fail</InputLabel>
  <Select
    required
    label="Status"
    onChange={(e) => {
      setChanged(true);
      setModAsset({
         ...modAsset,
         inspectionPassFail: e.target.value,
         inspectionReason: '',
      });
      }}>
    <MenuItem value="Pass">Pass</MenuItem>
    <MenuItem value="Fail">Fail</MenuItem>
  </Select>
</FormControl>
</Box>
<Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      autoComplete="off"
    >
        <TextField 
        fullWidth
          margin="dense"
          id="inspectionDate" 
          label="Inspection Date" 
          variant="outlined" 
          type="date" 
          value={modAsset.lastInspectionDate} 
         onChange={(e) => {
          setChanged(true);
            setModAsset({
               ...modAsset,
               lastInspectionDate: e.target.value,
            });
            }}
        />
        </Box>
       <Box
          margin="dense">
          <FormControl fullWidth>
       <TextField
          id="inspectionComments"
          label="Inspection Comments"
          multiline
          rows={4}
          onChange={(e) => {
            setModAsset({
               ...modAsset,
               inspectionComments: e.target.value,
            });
            }}
        />
        </FormControl>
     </Box>
     <div className="mb-2">
                            <Button variant="contained"
                                onClick={(e) => {
                                    window.location.href='/?assetId='+[modAsset.assetId]
                                }}
                            >
                                Cancel Inspection
                            </Button>
                        </div>
{changed ? ( <form
                        className="w-full max-w-sm"
                        id="asset"
                    >
                        <div className="mb-2" align='center'>
                            <Button variant="contained"
                                form="asset"
                                onClick={updateAsset}
                            >
                                Save Inspection Results
                            </Button>
                        </div>
                        </form>
                    ) : null
                    }
        </div>
  </div>
)};
                              
export default Inspect