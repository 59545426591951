import React, { useEffect, useState } from "react";
import { baseUrl } from '../shared';
import Login from '../components/login'
import Modal from "../components/upload.js";
import './App.css';
import Search from "../components/search";
import Edit from './edit/editAsset';
import Inspect from './edit/inspectAsset';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, TableHead } from "@mui/material";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { redirect } from "react-router-dom";

const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
    var assetIdUrl = urlParams.get('assetId')
      console.log('Asset ID: ', assetIdUrl);

function AssetView() {
  const [asset, setAsset] = useState();
  const [editing, setEditing] = useState(false);
  const [inspecting, setInspecting] = useState(false);
  const [addAttachment, setAddAttachment] = useState(false);
  var [attachments, setAttachments] = useState([]);
  const [attachmentsLoaded, setAttachmentsLoaded] = useState(false);
  var [fieldKey, setFieldKey] = useState();


  useEffect(()=> {
    console.log('Asset ',asset);
    console.log('Edit State:',editing);
    console.log('Inspecting State ',inspecting);
    console.log('Attachments: ',attachments)
  });
  
  useEffect(() => {
    if(assetIdUrl === null) {
    } else {
      const url = baseUrl + assetIdUrl;
      fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
      .then((response) => response.json())
      .then((data) => {
         setAsset(data[0]);
         setFieldKey(`${Math.floor((Math.random() * 1000))}-min`);
      })
      .catch((err) => {
         console.log(err.message);
      });
    }
  }, []);

  function showAttachments() {
      const url = baseUrl + 'attachmentList/' + asset.assetId;
      fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
        .then((res) => {
          return res.json();
      }).then((json) => {
           setAttachments(json);
           setAttachmentsLoaded(true);
           console.log(attachments);
      })
      .catch((err) => {
         console.log(err.message);
      });
    }

    function hideAttachments() {
      setAttachmentsLoaded(false);
    }

    function getSelectedAttachment(imageUrl) {
      // imageUrl.preventDefault();
      const url = baseUrl + 'retrieve/attachment';
      fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken'),
            'imageUrl': imageUrl,
        }})
        .then((res) => res.blob())
        .then((blob) => {
          const fileURL = URL.createObjectURL(blob);
          const fileWindow = window.open();
          fileWindow.location.href = fileURL;       
        })
      .catch((err) => {
         console.log(err.message);
      });
    } 

  return (
    
    <div className="assetView">
    {!asset && !localStorage.getItem('username') ? (
     <div>
        < Login />
    </div> ): null
    }

<br></br>

{!asset && !editing && !inspecting && localStorage.getItem('canSearch') === '1' ? (
  <div>
    < Search />
  </div>
): null}


    { asset && !editing && !inspecting ? (
      <div>  
        {!addAttachment ? (
          <div>
          <Box 
            sx={{ minWidth: 300 }}
            margin="dense">
          <TextField 
            fullWidth
            margin="dense"
            id="assetId" 
            key={fieldKey}
            readOnly
            label="Asset Id" 
            variant="outlined" 
            type="text" 
            InputProps={{
              readOnly: true,
            }}
            value={asset.assetId} 
          />
          </Box>
          <Box 
            fullWidth
            margin="dense">
          <TextField 
            fullWidth
            margin="dense"
            id="customer" 
            key={fieldKey}
            readOnly
            label="Customer" 
            variant="outlined" 
            type="text" 
            InputProps={{
              readOnly: true,
            }}
            value={asset.customer} 
          />
          </Box>
          <Box 
            fullWidth
            margin="dense">
          <TextField 
            fullWidth
            margin="dense"
            id="type" 
            key={fieldKey}
            readOnly
            label="Type" 
            variant="outlined" 
            type="text" 
            InputProps={{
              readOnly: true,
            }}
            value={asset.type} 
          />
          </Box>
          <Box 
            fullWidth
            margin="dense">
          <TextField 
            fullWidth
            margin="dense"
            id="site" 
            key={fieldKey}
            readOnly
            label="Site" 
            variant="outlined" 
            type="text" 
            InputProps={{
              readOnly: true,
            }}
            value={asset.site} 
          />
          </Box>
          <Box 
            fullWidth
            margin="dense">
          <TextField 
            fullWidth
            margin="dense"
            id="make" 
            key={fieldKey}
            readOnly
            label="Make" 
            variant="outlined" 
            type="text" 
            InputProps={{
              readOnly: true,
            }}
            value={asset.make} 
          />
          </Box>
          <Box 
            fullWidth
            margin="dense">
          <TextField 
            fullWidth
            margin="dense"
            id="model" 
            key={fieldKey}
            readOnly
            label="Model" 
            variant="outlined" 
            type="text" 
            InputProps={{
              readOnly: true,
            }}
            value={asset.model} 
          />
          </Box>
          <Box 
            fullWidth
            margin="dense">
          <TextField 
            fullWidth
            margin="dense"
            id="serialNo" 
            key={fieldKey}
            readOnly
            label="Serial Number" 
            variant="outlined" 
            type="text" 
            InputProps={{
              readOnly: true,
            }}
            value={asset.serialNo} 
          />
          </Box>
          <Box 
            fullWidth
            margin="dense">
          <TextField 
            fullWidth
            margin="dense"
            id="location" 
            key={fieldKey}
            readOnly
            label="Location" 
            variant="outlined" 
            type="text" 
            InputProps={{
              readOnly: true,
            }}
            value={asset.location} 
          />
          </Box>
          <Box 
            fullWidth
            margin="dense">
          <TextField 
            fullWidth
            margin="dense"
            id="lastInspectionDate" 
            key={fieldKey}
            readOnly
            label="Last Inspection Date" 
            variant="outlined" 
            type="text" 
            InputProps={{
              readOnly: true,
            }}
            value={asset.lastInspectionDate} 
          />
          </Box>
          <Box 
            fullWidth
            margin="dense">
          <TextField 
            fullWidth
            margin="dense"
            id="inspectionPassFail" 
            key={fieldKey}
            readOnly
            label="Inspection Result" 
            variant="outlined" 
            type="text" 
            InputProps={{
              readOnly: true,
            }}
            value={asset.inspectionPassFail} 
          />
          </Box>
          <Box 
            fullWidth
            margin="dense">
          <TextField 
            fullWidth
            multiline
            maxRows={4}
            margin="dense"
            id="inspectionComments" 
            key={fieldKey}
            readOnly
            label="Inspection Comments" 
            variant="outlined" 
            type="text" 
            InputProps={{
              readOnly: true,
            }}
            value={asset.inspectionComments} 
          />
          </Box>
          </div>
    ):null}
    { asset && !attachmentsLoaded && !addAttachment ? (
  <div className="mb-2" align='center'>
                            <Button
                                margin="dense"
                                variant="contained"
                                onClick={showAttachments}
                            >
                                View Attachments
                            </Button>
          </div>
): null}
{ asset && attachmentsLoaded ? (
<div className="mb-2" align='center'>
                        <Button
                            margin="dense"
                            variant="contained"
                            onClick={hideAttachments}
                        >
                            Hide Attachments
                        </Button>
<TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table" margin="dense">
        <TableHead>
          <TableCell align="center">
            Attachments
          </TableCell>
        </TableHead>
      <TableBody>
                      {attachments.map((list, index) => (
                        <TableRow key={index}>
                            <TableCell align="center" onClick={(e) => getSelectedAttachment(list.imageUrl)}>{list.image_name}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
      </Table>
    </TableContainer>
      </div>
): null}
<div className="mb-2" align='center'>
  { !addAttachment && asset && localStorage.getItem('canInspectAsset') === '1' ? (
                            <IconButton
                                margin="dense"
                                variant="contained"
                                onClick={(e) => {
                                    setInspecting(true);
                                }}
                            >
                            <Tooltip title="Inspect Asset">
                                <SearchIcon />
                            </Tooltip>
                            </IconButton>
): null}

{ !addAttachment && asset && localStorage.getItem('canEditAsset') === '1' ? (
                            <IconButton
                                margin="dense"
                                variant="contained"
                                onClick={(e) => {
                                    setEditing(true);
                                }}>
                            <Tooltip title="Edit Asset">
                                <EditIcon />
                            </Tooltip>
                            </IconButton>
): null}
{ !addAttachment && asset && localStorage.getItem('canAddAttachment') === '1' ? (
                            <IconButton
                                margin="dense"
                                variant="contained"
                                onClick={(e) => {
                                    setAddAttachment(true);
                                }}>
                                <Tooltip title="Add Attachment">
                                <CloudUploadIcon />
                            </Tooltip>
                            </IconButton>
): null}
 {addAttachment && <Modal foundAsset={asset}/> }
 
{ !addAttachment ? (
                            <IconButton
                                margin="dense"
                                variant="contained"
                                onClick={(e) => {
                                    window.location.href='/'
                                }}>
                                <Tooltip title="Clear Asset">
                                <CancelIcon />
                            </Tooltip>
                            </IconButton>
          ): null}
                   
                   </div>
          

  
  </div>
    ): null}

{ !addAttachment && editing && localStorage.getItem('canEditAsset') === '1' ? (        
<Edit 
foundAsset={asset}/>
): null}

{ !addAttachment && inspecting && localStorage.getItem('canEditAsset') === '1' ? (        
<Inspect 
foundAsset={asset}/>
): null}
<br />
<br />
  </div>
);
}
export default AssetView;