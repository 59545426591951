import React, { useEffect, useState } from "react";
import { baseUrl } from "../shared";
import '../pages/App.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { AlignHorizontalRight } from "@mui/icons-material";

export function Login() {
  
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState ("");
    const [timeToLogin, setTimeToLogin] = useState (false)
    
    const [loginStatus, setLoginStatus] = useState(false);

    useEffect(()=> {
      if (!localStorage.getItem('token')) {
        setLoginStatus(false);
      } else {
        setLoginStatus(true);
      }
    },[]);
  
    function TimeToLogin() {
      setTimeToLogin(true)
    } 
    function NoLogin() {
      setTimeToLogin(false)
    } 

    const login = () => {
        console.log('login');
    const url = baseUrl+"user/login";
    var payload = {
      "username": username,
      "password": password,
    }
    fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',            
        },
        body: ( "json", JSON.stringify( payload ) )
      })
        .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if (!data.auth) {
          setLoginStatus(false);
        } else {
          console.log(data);
          localStorage.setItem("token", data.token)
          localStorage.setItem("bToken", data.bToken)
          localStorage.setItem("username", data.result.username)
          localStorage.setItem("name", data.result.name)
          localStorage.setItem("canSearch", data.result.canSearch)
          localStorage.setItem("canAddAsset", data.result.canAddAsset)
          localStorage.setItem("canEditAsset", data.result.canEditAsset)
          localStorage.setItem("canDeleteAsset", data.result.canDeleteAsset)
          localStorage.setItem("canInspectAsset", data.result.canInspectAsset)
          localStorage.setItem("canAddAttachment", data.result.canAddAttachment)
          localStorage.setItem("canRegisterUser", data.result.canRegisterUser)
          localStorage.setItem("canUpdateUser", data.result.canUpdateUser)
          setLoginStatus (true);
          window.location.href='/'
        }
      });
    };
  
    return (
      <div className="login">
      <Box 
           margin="dense">
          <p>Login</p>
          <br />
            <TextField
                fullWidth
                label="Username" 
                variant="outlined" 
                type="text" 
                onChange = { (e) => {
                    setUsername (e.target.value);
                }}
            />
            <TextField
                fullWidth
                margin="dense"
                label="Password" 
                variant="outlined" 
                type="password" 
                onChange = { (e) => {
                    setPassword (e.target.value);
                }}
                onKeyDown={(e) => (
                  e.keyCode === 13 ? login(e) : null
                )}
            />
            <Button variant="contained" onClick={login} style={{float: 'right'}}>Login</Button>
     </Box>
      </div>
    );
  }

export default Login